<template>
  <div>
    <form-panel
      ref="form"
      :form="form"
      :submitText="submitText"
      :footerShow="data.orderStatus === 1 || data.orderStatus === 2"
      :submitBefore="submitBefore"
      v-bind="submitConfig"
      @update="update"
    >
      <col2-block title="订单信息">
        <col2-item label="业主姓名" :text="data.userName" />
        <col2-item label="联系电话">{{data.userPhone | phoneHandle}}</col2-item>
        <col2-item label="归属项目" :text="data.communityName" />
        <col2-item label="业主地址" :text="data.address" />
        <col2-item label="下单时间" :text="data.intime" />
        <col2-item label="订单状态" :text="availableMap[data.orderStatus]" />
        <col2-item label="消耗积分" :text="`${data.usePoint || ''}`" />
      </col2-block>
      <col2-block title="订单内容">
        <table-panel
          :headers="orderHeaders"
          :tableData="tableData"
          :hasOperateColumn="false">
        </table-panel>
      </col2-block>
      <col2-block title="处理情况">
        <div></div>
        <el-form-item label="处理反馈" prop="feedback" v-if="data.orderStatus === 3 || data.orderStatus === 2">
          <v-textarea v-model="form.feedback" :disabled="data.orderStatus === 3" :rules="[{ required: true, message: '请输入处理反馈', trigger: 'blur' }]"/>
        </el-form-item>
      </col2-block>
    </form-panel>
  </div>
</template>
<script>
import {
  getOrderDetailURL,
  updateOrderURL
} from './api'
import {
  availableMap
} from './map'
import { Col2Block, Col2Item, TablePanel } from '@/components/bussiness'

export default {
  components: {
    Col2Block,
    Col2Item,
    TablePanel
  },
  data () {
    return {
      availableMap,
      submitConfig: {
        queryUrl: getOrderDetailURL,
        submitUrl: updateOrderURL,
        submitMethod: 'PUT'
      },
      // submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
      data: {},
      form: {
        feedback: '用户您好，您的积分兑换订单xxx已经处理完成，祝生活愉快！'
      },
      orderHeaders: [
        {
          prop: 'productName',
          label: '商品名称'
        },
        {
          prop: 'requiredScore',
          label: '单价'
        },
        {
          prop: 'bookedNumber',
          label: '兑换数量'
        }
      ],
      tableData: []
    }
  },
  computed: {
    submitText () {
      return this.data.orderStatus === 1 ? '接收' : '开始处理'
    }
  },
  filters: {
    phoneHandle (val) {
      if (val) {
        let vaild = /(\d{3})\d*(\d{4})/
        return val.replace(vaild, '$1****$2')
      } else {
        return ''
      }
    }
  },
  mounted () {
    this.$setBreadcrumb('积分订单详情')
    this.$refs.form.getData({ id: this.$route.query.id })
  },
  methods: {
    update (data) {
      this.data = data
      this.form.feedback = `用户您好，您的积分兑换订单${data.orderCode}已经处理完成，祝生活愉快！`
      this.tableData = [{
        productName: data.productName,
        requiredScore: `${data.usePoint}积分`,
        bookedNumber: data.changeNum
      }]
    },
    submitBefore (data) {
      if (this.data.orderStatus === 1) {
        data.orderStatus = 2
        data.feedback = ''
      } else if (this.data.orderStatus === 2) {
        data.orderStatus = 3
      }
      data.id = this.data.id
      // data.orderStatus = this.data.orderStatus
      // data.orderUserId = this.data.userid
      // data.orderCode = this.data.orderCode
      data.regionId = this.data.regionId
      // data.dataObject = this.data.dataObject
      return true
    }
  }
}
</script>
