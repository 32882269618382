var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "form",
            attrs: {
              form: _vm.form,
              submitText: _vm.submitText,
              footerShow:
                _vm.data.orderStatus === 1 || _vm.data.orderStatus === 2,
              submitBefore: _vm.submitBefore,
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-block",
            { attrs: { title: "订单信息" } },
            [
              _c("col2-item", {
                attrs: { label: "业主姓名", text: _vm.data.userName },
              }),
              _c("col2-item", { attrs: { label: "联系电话" } }, [
                _vm._v(_vm._s(_vm._f("phoneHandle")(_vm.data.userPhone))),
              ]),
              _c("col2-item", {
                attrs: { label: "归属项目", text: _vm.data.communityName },
              }),
              _c("col2-item", {
                attrs: { label: "业主地址", text: _vm.data.address },
              }),
              _c("col2-item", {
                attrs: { label: "下单时间", text: _vm.data.intime },
              }),
              _c("col2-item", {
                attrs: {
                  label: "订单状态",
                  text: _vm.availableMap[_vm.data.orderStatus],
                },
              }),
              _c("col2-item", {
                attrs: {
                  label: "消耗积分",
                  text: `${_vm.data.usePoint || ""}`,
                },
              }),
            ],
            1
          ),
          _c(
            "col2-block",
            { attrs: { title: "订单内容" } },
            [
              _c("table-panel", {
                attrs: {
                  headers: _vm.orderHeaders,
                  tableData: _vm.tableData,
                  hasOperateColumn: false,
                },
              }),
            ],
            1
          ),
          _c(
            "col2-block",
            { attrs: { title: "处理情况" } },
            [
              _c("div"),
              _vm.data.orderStatus === 3 || _vm.data.orderStatus === 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "处理反馈", prop: "feedback" } },
                    [
                      _c("v-textarea", {
                        attrs: {
                          disabled: _vm.data.orderStatus === 3,
                          rules: [
                            {
                              required: true,
                              message: "请输入处理反馈",
                              trigger: "blur",
                            },
                          ],
                        },
                        model: {
                          value: _vm.form.feedback,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "feedback", $$v)
                          },
                          expression: "form.feedback",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }