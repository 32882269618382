// 获取列表
const getListURL = `${API_CONFIG.shopBaseURL}integralOrder/getIntegralOrderList`
// 导出列表
const exportListURL = `${API_CONFIG.shopBaseURL}pointOrder/export`
// 获取组织列表
const getOrgListURL = `${API_CONFIG.butlerBaseURL}orgInfo/searchOrgByKeyword`
// 获取订单详情
const getOrderDetailURL = `${API_CONFIG.shopBaseURL}integralOrder/getIntegralOrderDetail`
// 保存
const updateOrderURL = `${API_CONFIG.shopBaseURL}integralOrder/updateIntegralOrder`

export {
  getListURL,
  exportListURL,
  getOrgListURL,
  getOrderDetailURL,
  updateOrderURL
}
